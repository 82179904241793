import React, { createContext, useContext, useReducer } from 'react';

// Define your initial state and reducer function
const initialState = {
  selectedPolicy: null,
  error:''
  // Add more states here as needed
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_POLICY':
      return { ...state, selectedPolicy: action.payload };
    case 'SET_AGENCY_CODE':
      return { ...state, agency_code: action.payload };
    case 'ERROR_MESSAGE':
      return { ...state, error: action.payload };
    // Add more cases for other state changes here
    default:
      return state;
  }
};

// Create a new context
const AppContext = createContext();

// Create a custom hook for using the context
export function useAppContext() {
  return useContext(AppContext);
}

// Create a context provider component
export function AppContextProvider({ children }) {
  // Use useReducer to manage state changes
  const [state, dispatch] = useReducer(reducer, initialState);

  // Wrap your app with the context provider
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}
