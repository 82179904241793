import React, { createContext, useContext } from 'react';

const initialContext = null;
const ConfigContext = createContext(initialContext);

export const useConfig = () => useContext(ConfigContext);

const ConfigProvider = ({ children, config }) => {
    return (
        <ConfigContext.Provider value={{...initialContext, ...config}}>
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigProvider;