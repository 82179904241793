import { dataClickGTM, dataIdGTM } from "../utils/TrackingService";
import { CAR_HIRE_EXCESS_UK, CAR_HIRE_EXCESS_IE } from "./companies";
import { configChe } from "./config_che";
import { getCurrentOrganisation } from "./getCurrentOrganisation";
import { link } from "./link";

const quoteLink155 =
    process.env.MIX_CHE_155_QUOTE_URL ||
    "https://carhireexcess-co-uk.ppr.halolynx.com";
const quoteLink156 =
    process.env.MIX_CHE_156_QUOTE_URL ||
    "https://carhireexcess-ie.ppr.halolynx.com/";
const FAQLink155 =
    process.env.MIX_CHE_155_FAQ_URL ||
    "https://carhireexcess-co-uk.ppr.halolynx.com/faq";
const FAQLink156 =
    process.env.MIX_CHE_156_FAQ_URL ||
    "https://carhireexcess-ie.ppr.halolynx.com/faq";
const contactLink155 =
    process.env.MIX_CHE_155_CONTACT_URL ||
    "https://carhireexcess-co-uk.ppr.halolynx.com/contact";
const contactLink156 =
    process.env.MIX_CHE_156_CONTACT_URL ||
    "https://carhireexcess-ie.ppr.halolynx.com/contact";

export const configs = {
    [CAR_HIRE_EXCESS_UK]: {
        ...configChe,
        header: {
            logo: "che-logo.svg",
            externalLinks: [
                link("Get a quote", `${quoteLink155}`, "url", "", {
                    dataId: dataIdGTM.get_quote_btn,
                    dataClickText: dataClickGTM.get_quote_btn,
                }),
                link("Claims", "/claims", "", "", {
                    dataId: dataIdGTM.claims_btn,
                    dataClickText: dataClickGTM.claims_btn,
                }),
                link("FAQ", `${FAQLink155}`, "url", "", {
                    dataId: dataIdGTM.faq_btn,
                    dataClickText: dataClickGTM.faq_btn,
                }),
                link("Contact us", `${contactLink155}`, "url", "", {
                    dataId: dataIdGTM.contact_us_btn,
                    dataClickText: dataClickGTM.contact_us_btn,
                }),
            ],
            links: [
                link("Your Policy", "/dashboard", "", "",{
                    dataId: dataIdGTM.your_policy,
                    dataClickText: dataClickGTM.your_policy,
                }),
                link("Documents", "/documents", "", "",{
                    dataId: dataIdGTM.documents,
                    dataClickText: dataClickGTM.documents,
                }),
                link("Claims", "/claims", "", "",{
                    dataId: dataIdGTM.claims_btn,
                    dataClickText: dataClickGTM.claims_btn,
                }),
            ],
            helpButton: {
                links: [
                    link(
                        "0333 355 6182",
                        "tel:0333 355 6182",
                        "url",
                        "phone.svg"
                    ),
                    link(
                        "info@carhireexcess.com",
                        "mailto:info@carhireexcess.com",
                        "url",
                        "mail.svg"
                    ),
                ],
                info: [
                    { heading: "Mon - Fri", text: "9:00am to 5:00pm" },
                    { heading: "Sat", text: "10:00am - 2:00pm" },
                    { heading: "Sunday", text: "Closed" },
                ],
            },
            avatar: {
                userInfo: {
                    name: "Olivia Rhye",
                    email: "design@covermore.com",
                }, //todo: remove after login complete,
                links: [
                    link(
                        "Billing Details",
                        "/billing-details",
                        "",
                        "credit-card.svg",
                        {
                            dataId: dataIdGTM.billing_details,
                            dataClickText: dataClickGTM.billing_details,
                        }
                        
                    ),
                    link("FAQ", `${FAQLink155}`, "url", "help-icon.svg", {
                        dataId: dataIdGTM.faq_btn,
                        dataClickText: dataClickGTM.faq_btn,
                    }),
                    link(
                        "Contact",
                        "https://carhireexcess-co-uk.ppr.halolynx.com/contact",
                        "url",
                        "mail.svg", {
                            dataId: dataIdGTM.contact_us_btn,
                        dataClickText: dataClickGTM.contact_us_btn,
                    }
                    ),
                ],
            },
        },
        footer: {
            links: [
                link(
                    "Privacy policy",
                    "https://www.blueinsurance.ie/PrivacyPolicy/UK/",
                    "url",
                    ""
                ),
                link(
                    "Cookie policy",
                    `${quoteLink155}/cookie-policy`,
                    "url",
                    ""
                ),
                link(
                    "Contact",
                    contactLink155,
                    "url",
                    ""
                ),
            ],
            description:
                "Cover-More Blue Insurance Services Limited trading as Carhireexcess.com is a private limited company incorporated in Ireland with company number 345681 and with its UK branch office registered at 82 Oxford Road, Uxbridge, Middlesex, UB8 1UX. It is authorised and regulated in the United Kingdom by the Financial Conduct Authority, Firm Reference No. 984290.UK Office: Parkview, 82 Oxford Road, Uxbridge, UB8 1UX",
            copyRight:
                "© 2023 Cover-More Insurance Service Ltd. All rights reserved.",
        },
        claimsPage: {
            claimPhone: `0333 355 0250`,
            claimEmail: `excessclaims@defendinsurance.co.uk`,
            claimTitle: ` All claims must be notified to the claims
administrator, their details are below. You
should do this within 31 days of the end of the
rental agreement in which the incident happened.`,
            howToClaim: (claimPhone, claimEmail) =>
                ` Policies issued on or after <b>1 March 2020</b>, please
                  contact Defend Insurance Group on <b>Telephone:
                <b><a href="tel:${claimPhone}">${claimPhone}</a> Email:
                <a href="mailto:${claimEmail}"> ${claimEmail}</a> </b>`,
            howToClaimSteps: [
                `The rental agreement`,
                `Your charge receipt (if separate from the rental agreement)`,
                `Police Report if the incident by law required the Police to attend`,
                `Photographs of the damage to the rental vehicle (and images of the vehicle before the incident if available)`,
                `The accident report from the car rental company or agency`,
                `Invoices/Receipts/other documents confirming the amount you have paid in respect of damage for which the car rental company or agency holds you responsible`,
                `Your credit card statement showing payment of the damages claimed`,
            ],
        },
        googleTagId: process.env.MIX_GTM_ID_155 || "GTM-MXXM7M42",
        sessionExpiredLinks: [
            link("Get a quote", `${quoteLink155}`, "url", ""),
            link("Retrieve a quote", `${quoteLink155}`, "url", ""),
            link("Ask us a question", `${quoteLink155}`, "url", ""),
            link("Customer login", `${quoteLink155}`, "url", ""),
            link("About our car hire insurance", `${quoteLink155}`, "url", ""),
        ],
        theme_css: "che_uk",
        agency_code: 155,
    },
    [CAR_HIRE_EXCESS_IE]: {
        ...configChe,
        header: {
            logo: "che-logo.svg",
            externalLinks: [
                link("Get a quote", `${quoteLink156}`, "url", "", {
                    dataId: dataIdGTM.get_quote_btn,
                    dataClickText: dataClickGTM.get_quote_btn,
                }),
                link("Claims", "/claims", "", "", {
                    dataId: dataIdGTM.claims_btn,
                    dataClickText: dataClickGTM.claims_btn,
                }),
                link("FAQ", `${FAQLink156}`, "url", "", {
                    dataId: dataIdGTM.faq_btn,
                    dataClickText: dataClickGTM.faq_btn,
                }),
                link("Contact us", `${contactLink156}`, "url", "", {
                    dataId: dataIdGTM.contact_us_btn,
                    dataClickText: dataClickGTM.contact_us_btn,
                }),
            ],
            links: [
                link("Your Policy", "/dashboard", "", "",{
                    dataId: dataIdGTM.your_policy,
                    dataClickText: dataClickGTM.your_policy,
                }),
                link("Documents", "/documents", "", "",{
                    dataId: dataIdGTM.documents,
                    dataClickText: dataClickGTM.documents,
                }),
                link("Claims", "/claims", "", "",{
                    dataId: dataIdGTM.claims_btn,
                    dataClickText: dataClickGTM.claims_btn,
                }),
            ],
            helpButton: {
                links: [
                    link(
                        "0818 44 44 47",
                        "tel:0818 44 44 47",
                        "url",
                        "phone.svg"
                    ),
                    link(
                        "info@carhireexcess.com",
                        "mailto:info@carhireexcess.com",
                        "url",
                        "mail.svg"
                    ),
                ],
                info: [
                    { heading: "Mon - Fri", text: "9:00am to 5:00pm" },
                    { heading: "Sat", text: "10:00am - 2:00pm" },
                    { heading: "Sunday", text: "Closed" },
                ],
            },
            avatar: {
                userInfo: {
                    name: "Olivia Rhye",
                    email: "design@covermore.com",
                }, //todo: remove after login complete,
                links: [
                    link(
                        "Billing Details",
                        "/billing-details",
                        "",
                        "credit-card.svg",
                        {
                            dataId: dataIdGTM.billing_details,
                            dataClickText: dataClickGTM.billing_details,
                        }
                    ),
                    link("FAQ", `${FAQLink156}`, "url", "help-icon.svg", {
                        dataId: dataIdGTM.faq_btn,
                        dataClickText: dataClickGTM.faq_btn,
                    }),
                    link("Contact", `${contactLink156}`, "url", "mail.svg", {
                        dataId: dataIdGTM.contact_us_btn,
                        dataClickText: dataClickGTM.contact_us_btn,
                    }),
                ],
            },
        },
        footer: {
            links: [
                link(
                    "Privacy policy",
                    "https://www.blueinsurance.ie/PrivacyPolicy/IE/",
                    "url",
                    ""
                ),
                link(
                    "Cookie policy",
                    `${quoteLink156}/cookie-policy`,
                    "url",
                    ""
                ),
                link("Contact", `${contactLink156}`, "url", ""),
            ],
            description:
                "Cover-More Blue Insurance Services Limited trading as Carhireexcess.com is a private limited company incorporated in Ireland with company number 345681 and with its UK branch office registered at 82 Oxford Road, Uxbridge, Middlesex, UB8 1UX. It is authorised and regulated in the United Kingdom by the Financial Conduct Authority, Firm Reference No. 984290.UK Office: Parkview, 82 Oxford Road, Uxbridge, UB8 1UX",
            copyRight:
                "© 2023 Cover-More Insurance Service Ltd. All rights reserved.",
        },
        claimsPage: {
            claimPhone: `0818 444 210`,
            claimEmail: `excessclaims@defendinsurance.co.uk`,
            claimTitle: ` All claims must be notified to the claims
administrator, their details are below. You
should do this within 31 days of the end of the
rental agreement in which the incident happened.`,
            howToClaim: (claimPhone, claimEmail) =>
                ` Policies issued on or after <b>1 March 2020</b>, please
  contact Defend Insurance Group on <b>Telephone:
<b><a href="tel:${claimPhone}">${claimPhone}</a> Email:
<a href="mailto:${claimEmail}"> ${claimEmail}</a> </b>`,
            howToClaimSteps: [
                `The rental agreement`,
                `Your charge receipt (if separate from the rental agreement)`,
                `Police Report if the incident by law required the Police to attend`,
                `Photographs of the damage to the rental vehicle (and images of the vehicle before the incident if available)`,
                `The accident report from the car rental company or agency`,
                `Invoices/Receipts/other documents confirming the amount you have paid in respect of damage for which the car rental company or agency holds you responsible`,
                `Your credit card statement showing payment of the damages claimed`,
            ],
        },
        googleTagId: process.env.MIX_GTM_ID_156 || "GTM-MXXM7M42",
        sessionExpiredLinks: [
            link("Get a quote", `${quoteLink155}`, "url", ""),
            link("Retrieve a quote", `${quoteLink155}`, "url", ""),
            link("Ask us a question", `${quoteLink155}`, "url", ""),
            link("Customer login", `${quoteLink155}`, "url", ""),
            link("About our car hire insurance", `${quoteLink155}`, "url", ""),
        ],
        theme_css: "che_uk",
        agency_code: 156,
    },
};

export const getOrganisationConfig = (organisation) => {
    return configs[organisation];
};

export const getCurrentOrganisationConfig = () => {
    return getOrganisationConfig(getCurrentOrganisation());
};
