import moment from "moment";
import { dataClickGTM, dataIdGTM } from "./TrackingService";

export const logger = (...args) => {
    if (process.env.NODE_ENV.toLowerCase() === 'production')
        return;
    
    console.log(...args);
    // return
};

export const backBtnHandler = () => {
    window.history.back();
};

export const scrollToError = (errorField) => {
    if (errorField) {
        errorField?.scrollIntoView({ behavior: "smooth" });
    }
};

export const findImageFilenames = (obj, resultArray = []) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (typeof value === "string") {
                const val = value.toLocaleLowerCase();
                if (
                    val.includes(".jpg") ||
                    val.includes(".png") ||
                    val.includes(".svg")
                ) {
                    resultArray.push(value);
                }
            } else if (typeof value === "object") {
                findImageFilenames(value, resultArray);
            }
        }
    }
    return resultArray;
};

export const is30DaysPassed = (expirationDate) => {
    // Parse the expiration date string using moment
    const expirationDateMoment = moment(expirationDate, "DD/MM/YYYY HH:mm:ss");

    // Calculate the date 30 days after the expiration date
    const thirtyDaysAfterExpiration = moment(expirationDateMoment).add(
        30,
        "days"
    );

    // Get the current date and time using moment
    const currentDate = moment();

    // Compare the current date and time with 30 days after expiration date
    return currentDate.isAfter(thirtyDaysAfterExpiration);
};

export const isExpiredPolicy = (expirationDateStr) => {
    // Parse the expiration date string using moment
    const expirationDate = moment(expirationDateStr, "DD/MM/YYYY HH:mm:ss");

    // Get the current date and time using moment
    const currentDate = moment();

    // Compare the current date and time with the expiration date
    return currentDate.isAfter(expirationDate);
};

export const is14DaysPassed = (dateString) => {
    const currentDate = moment();
    const givenDate = moment(dateString);

    // Calculate the difference in days
    const daysDifference = currentDate.diff(givenDate, "days");

    // Check if 14 days have passed
    return daysDifference >= 14;
};

export const isPoliciesEndingSoon = (policyExpiryDate, noOfWeeks = 4) => {
    const weeks = 7 * noOfWeeks
    // Parse the policy expiry date using moment with the format 'DD/MM/YYYY HH:mm:ss'
    const policyEndDate = moment(policyExpiryDate, "DD/MM/YYYY HH:mm:ss");
    // Get the current date at midnight using moment
    const today = moment().startOf("day");

    // Calculate the difference in weeks between today and the end date
    // const weeksRemaining = policyEndDate.diff(today, "weeks");
    // console.log(weeksRemaining, 'week remaining')
    // Check if there are 4 weeks or fewer remaining
    // return weeksRemaining <= 4;

    // Calculate the difference in days between today and the end date
    const daysRemaining = policyEndDate.diff(today, "days");
    // Check if there are 28 days or fewer remaining
    return daysRemaining <= weeks;
};

export const isPoliciesEndingInSexWeeks = (policyExpiryDate) => {
    // Parse the policy expiry date using moment with the format 'DD/MM/YYYY HH:mm:ss'
    const policyEndDate = moment(policyExpiryDate, "DD/MM/YYYY HH:mm:ss");

    // Get the current date at midnight using moment
    const today = moment().startOf("day");

    // Calculate the difference in weeks between today and the end date
    const weeksRemaining = policyEndDate.diff(today, "weeks");

    // Check if there are 6 weeks or fewer remaining
    return weeksRemaining <= 6;
};

export const dayRemainingUntilPolicyEnd = (inputDate) => {
    const currentDate = moment();
    const endDate = moment(inputDate, "DD/MM/YYYY HH:mm:ss");
    const duration = moment.duration(endDate.diff(currentDate));
    const days = duration.asDays();
    return Math.floor(days);
};

// export const calculateProgress = (startDate, endDate) => {
//     const currentDate = moment();
//     const end = moment(endDate);

//     // Calculate the total duration of the policy in milliseconds
//     const totalDuration = end.diff(moment(startDate));

//     // Calculate the duration passed from the start date to the current date in milliseconds
//     const durationPassed = currentDate.diff(moment(startDate));

//     // Calculate the progress percentage
//     const progress = (durationPassed / totalDuration) * 100;

//     // Ensure the progress is within the range [0, 100]
//     return Math.min(Math.max(progress, 0), 100);
// };

export const calculateProgress=(policyEndDate,days)=> {
    // Parse the policy end date using moment
    const endDate = moment(policyEndDate);
  
    // Get the current date
    const currentDate = moment();
  
    // Calculate the difference in days between the current date and policy end date
    const remainingDays = endDate.diff(currentDate, 'days');
  
    // Calculate progress as a percentage
    const progress = Math.max(0, Math.min(100, (days - remainingDays) * (100 / days)));
  
    return progress;
  }

export const isAnnualPolicy = (policyName) => {
    let isAnnual = policyName?.toLowerCase()?.includes("annual");
    return isAnnual;
};

export const capitalizeVariable = (variable) => {
    if (typeof variable !== "string") {
        // Check if the input is a string
        return variable;
    }

    if (variable.length === 0) {
        // Handle empty string
        return variable;
    }

    // Capitalize the first letter and concatenate the rest of the string
    return variable.charAt(0).toUpperCase() + variable.slice(1);
};

export const calculateStartDate = (currentExpiryDate) => {
    // Parse the currentExpiryDate
    let formattedStartDate = moment(currentExpiryDate, "DD/MM/YYYY HH:mm:ss");
    const currentDate = moment();

    // Check if currentExpiryDate is greater than the current date
    if (formattedStartDate.isAfter(currentDate)) {
        // If currentExpiryDate is greater, add one day to it
        formattedStartDate.add(1, "days");
    } else {
        // If currentExpiryDate is not greater, use the current date
        formattedStartDate = currentDate;
    }

    // Format and return the resulting startDate
    return formattedStartDate.format("DD/MM/YYYY HH:mm:ss");
};

export const has24HoursPassed = (inputDate) => {
    const dateToCheck = moment(inputDate);
    const currentDate = moment();
    const hoursDifference = currentDate.diff(dateToCheck, "hours");
    return hoursDifference >= 24;
};

// export const isExpiryWithinDateWindow = (expiryDate, days) => {
//     if (!expiryDate || !days) return null;
//     // Parse the expiry date using moment
//     const parsedExpiryDate = moment(expiryDate);

//     // Calculate the difference in days between the current date and the expiry date
//     const daysRemaining = parsedExpiryDate?.diff(moment(), "days");

//     // Return true if there are 14 or fewer days remaining
//     return daysRemaining <= Number(days);
// };

export const isExpiryDateWindow = (expiryDate, days) => {
    if (!expiryDate || !days) return null;
    // Parse the expiry date using moment with the appropriate format
    const parsedExpiryDate = moment(expiryDate, "DD/MM/YYYY HH:mm:ss");

    // Calculate the difference in days between the current date and the expiry date
    const daysRemaining = parsedExpiryDate?.diff(moment(), "days");

    // Return true if there are 14 or fewer days remaining
    return daysRemaining <= Number(days);
};

// Static variable might pid will change
const european_annual_cover_pid = "ea";
const europe_single_trip_pid = "ed";
const worldwide_annual_cover_pid = "wa";
const worldwide_single_trip_pid = "wd";
const worldwide_sli_annual_pid = "wsa";
const worldwide_sli_single_trip = "wsd";

export const getGtmofProduct = (array) => {
    return array.map((e) =>
        e.pid.includes(european_annual_cover_pid)
            ? {
                ...e,
                gtmId: dataIdGTM.european_annual_mta_btn,
                gtmClickText: dataClickGTM.european_annual_mta_btn,
            }
            : e.pid.includes(worldwide_annual_cover_pid)
                ? {
                    ...e,
                    gtmId: dataIdGTM.worldwide_annual_mta_btn,
                    gtmClickText: dataClickGTM.worldwide_annual_mta_btn,
                }
                : e.pid.includes(europe_single_trip_pid)
                    ? {
                        ...e,
                        gtmId: dataIdGTM.european_daily_mta_btn,
                        gtmClickText: dataClickGTM.european_daily_mta_btn,
                    }
                    : e.pid.includes(worldwide_single_trip_pid)
                        ? {
                            ...e,
                            gtmId: dataIdGTM.worldwide_daily_mta_btn,
                            gtmClickText: dataClickGTM.worldwide_daily_mta_btn,
                        }
                        : e
    );
};


export const isPoliciesEndingSoonIEDocuments = (policyExpiryDate,week=4) => {
    let days = week * 7
    // Parse the policy expiry date using moment with the format 'DD/MM/YYYY HH:mm:ss'
    const policyEndDate = moment(policyExpiryDate, "DD/MM/YYYY HH:mm:ss");
    // Get the current date at midnight using moment
    const today = moment().startOf("day");
    // Calculate the difference in days between today and the end date
    const daysRemaining = policyEndDate.diff(today, "days");
    return daysRemaining <= parseInt(days);
};

export const iptOrignalPrice = (base,iptRate)=>{
    return base * (iptRate / 100);
    // return Math.round((base * (iptRate / 100)) * 10) / 10;
}