export const configChe = {
    theme: "che",
    assetsPath: "/assets/che",
    layoutVersion: "V1",
    dashboard: {
        addons: {
            additionalCover: [
                {
                    isCovered: true,
                    text: "Family cover",
                },
            ],
            covered: [
                {
                    isCovered: true,
                    text: "Zero excess",
                },
                {
                    isCovered: true,
                    text: "Cover up to £7,500",
                },
                {
                    isCovered: true,
                    text: "Fire, theft & vandalism",
                },
                {
                    isCovered: true,
                    text: "Tyres & windscreen",
                },
                {
                    isCovered: true,
                    text: "Keys & undercarriage",
                },
                {
                    isCovered: true,
                    text: "Personal possessions cover",
                },
                {
                    isCovered: true,
                    text: "Max continuous rental 62 days",
                },
            ],
            notCovered: [
                {
                    isCovered: false,
                    text: "Vehicles valued over £70,000",
                },
                {
                    isCovered: false,
                    text: "Motorhomes, caravans & camper vans",
                },
                {
                    isCovered: false,
                    text: "Vehicles with over 7 seats",
                },
                {
                    isCovered: false,
                    text: "Vehicles over 3.5 tonnes",
                },
                {
                    isCovered: false,
                    text: "Off road vehicles",
                },
                {
                    isCovered: false,
                    text: "Green motion car rental",
                },
            ],
        },
    },
    icons: {
        signOut: "signout.svg",
        helpCircle: "help-circle.svg",
        userIcon: "user-icon.svg",
        userPlus: "userPlus.svg",
        userThemeColor: "userThemeColor.svg",
        users: "users.svg",
        clock: "clock.svg",
        check: "check.svg",
        paymentMethod: "payment-methods.png",
        menueBar: "menu-bars.svg",
        cross: "cross.svg",
        chevronDown: "chevronDown.svg",
        chevronDownGray: "chevronDownGray.svg",
        plusCircle: "plusCircle.svg",
        plusCircleWhite: "plusCircleWhite.svg",
        refresh: "refresh.svg",
        file: "file.svg",
        arrowLeft: "arrow-left.svg",
        clockWhite: "clock-white.svg",
        fileText: "file-text.svg",
        editIcon: "edit.svg",
        download: "download.svg",
        alert: "alert.svg",
        email: "email.svg",
        calender: "calender.svg",
        calenderTheme: "calender-theme.svg",
        checkWhite: "checkWhite.svg",
        home: "home.svg",
        homeTheme: "home-theme.svg",
        trash: "trash.svg",
        checkboxChecked: "checkbox_checked.svg",
        checkbox: "checkbox.svg",
        visa: "payment-visa.svg",
        mastercard: "mastercard.svg",
        favIcon:"favicon-car-hire.png",
        octagenCircle: "octagenCircle.svg",
        checkCircle: "check-circle.svg",
        whiteCross: "white-cross.svg",
        alertTheme: "alert-theme.svg",
        themeInfo: "theme-info.svg",
        crossHeader:"cross-header.svg"
    },
    renewal_back_date_days: 30,
};
