import React, { useState } from 'react';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: process.env.MIX_SENTRY_LARAVEL_DSN,
});

const SentryErrorBoundary = ({ children }) => {
    const [error, setError] = useState(null);

    return (
        <Sentry.ErrorBoundary
            fallback={({ error, componentStack, resetError }) => (
                <div>
                    There appears to be an issue at the moment.
                    Information has been forwarded to the technical support team, and we are looking into it.
                    <button onClick={resetError}>Try Again</button>
                </div>
            )}
            onReset={() => setError(null)}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};

export default SentryErrorBoundary;
