import TagManager from "react-gtm-module";
import {logger} from './utils';



export const TrackCustomEvent=(eventType,agency_code)=>{
    const dataLayer = {
        event: eventType,
        ecommerce: {
            currency:
                agency_code === 155
                    ? "GBP"
                    : agency_code === 156
                    ? "EUR"
                    : "",
            value: 0.0,
            items: [
                {
                    item_id: "",
                    item_name: "",
                    affiliation: "Carhireexcess Renewals",
                    item_brand:
                        agency_code === 155
                            ? "Carhireexcess.co.uk"
                            : agency_code === 156
                            ? "Carhireexcess.ie"
                            : "",
                    item_category: "Elective Renewal Policy",
                    price: 0.0,
                    quantity: 0,
                },
            ],
        },
    };
    TagManager.dataLayer({
        dataLayer: { ...dataLayer, ecommerce: null },
    });
    TagManager.dataLayer({ dataLayer });

}

export const TrackCustomEvent2 = (eventType, agency_code, product, addons, policy, coupon = false, payment_type = false, sms_fee, pay_page = false) => {
    const dlItemsProduct = [
        {
            item_id: product?.pid_name,
            item_name: product?.front_end_title,
            affiliation: "Carhireexcess Renewals",
            item_brand: `Carhire excess ${product?.currency?.toLowerCase() ===
                "GBP".toLowerCase()
                ? "UK"
                : "IE"
                }`,
            item_category: "Elective Renewal Policy",
            price:
                Number(
                    parseFloat(
                        product?.currencies[product?.currency]
                            ?.total_rrp ?? 0
                    )?.toFixed(2)
                ) ?? 0,
            quantity: 1,
        },
    ];
    if (coupon) {
        dlItemsProduct[0].coupon = `${product.currencies[product.currency]?.discount_code ?? ""}`,
            dlItemsProduct[0].discount = `${product.currencies[product.currency]?.discount_value ?? ""
            }${product.currencies[product.currency]?.discount_type ?? ""}`
    }

    const getAddons = pay_page && coupon ? addons.filter(e => product.quote_metadata.selected_addons?.includes(e?.pid_name)) : coupon && !pay_page ? addons : addons.filter(e => policy.addons.includes(e?.pid))
    const dlItemsAddons = getAddons?.map((e) => {
        const item = {
            item_id: e.pid_name,
            item_name: e.front_end_title,
            affiliation: "Carhireexcess Renewals",
            item_brand: `Carhire excess ${product?.currency?.toLowerCase() === "GBP".toLowerCase() ? "UK" : "IE"
                }`,
            item_category: "Elective Renewal Policy",
            price: Number(
                parseFloat(
                    e?.currencies[product?.currency]?.total_rrp ?? 0
                )?.toFixed(2)
            ) ?? 0,
            quantity: 1,
        };

        if (coupon) {
            item.discount = `${e?.currencies[product?.currency]?.discount_value ?? ""
                }${e?.currencies[product?.currency]?.discount_type ?? ""}`;

            item.coupon = `${e?.currencies[product?.currency]?.discount_code ?? ""
                }`;
        }

        return item;
    });


    const dlItemsForGTM = [...dlItemsProduct, ...dlItemsAddons];

    const getTotalPrice = dlItemsForGTM.reduce(
        (acc, curent) => Number(acc) + Number(curent.price),
        0
    );
    const getSmsFeesIncluded = getTotalPrice + (sms_fee === true ? 0.99 : policy?.metadata?.sms_notification === true ? 0.99 : 0)
    const dataLayer = {
        event: eventType,
        ecommerce: {
            currency: agency_code === 155
                ? "GBP"
                : agency_code === 156
                    ? "EUR"
                    : "",
            value: Number(parseFloat(getSmsFeesIncluded ?? 0).toFixed(2)) ?? 0,
            items: dlItemsForGTM,
        },
    };
    if (coupon) {
        dataLayer.ecommerce.coupon = `${product.currencies[product.currency]?.discount_code ?? ""}`
    }
    if (payment_type) {
        dataLayer.ecommerce.payment_type = 'Credit Card'
    }
    TagManager.dataLayer({
        dataLayer: { ...dataLayer, ecommerce: null },
    });
    TagManager.dataLayer({ dataLayer });
}


export const TrackInteractionEvent = (type, dataset) => {
    if (dataset) {
        const { id, clickText, pagePath, pageTitle } = dataset;
        let dataLayer = {
            event: type,
            id,
            clickText,
        };

        if (pagePath) dataLayer.pagePath = pagePath;

        if (pageTitle) dataLayer.pageTitle = pageTitle;

        logger('TrackInteractionEvent dataLayer', dataLayer);
        

        TagManager.dataLayer({ dataLayer });
    } else {
        // eslint-disable-next-line no-console
        // console.log('Missing dataset for event', type);
    }
};

const categoryGTM = {
    banner: "Self Service - Banner",
    login: "Self Service - Loign",
    customerDetails: "Self Service - Customer Details",
    renewalPolicyDetails: "Self Service - Renewal - Policy details",
    renewalRenewOnExpiry: "Self Service - Renewal - Policy details",
    renewalQuote: "Self Service - Renewal - Quote",
    renewalPayment: "Self Service - Renewal - Payment",
    renewalConfirmation: "Self Service - Renewal - Confirmation",
    documents: "Self Service - Documents",
    makeAChange: "Self Service - Make a change",
    mta: "Self Service - MTA",
    changeDetails: "Self Service - Change details",
    additionalDrivers: "Self Service - Additional drivers",
    automaticRenewal: "Self Service - Automatic Renewal",
    cancelPolicy: "Self Service - Cancel policy",
};

export const dataIdGTM = {
    get_quote_btn: "get_quote_btn",
    claims_btn: "claims_btn",
    faq_btn: "faq_btn",
    contact_us_btn: "contact_us_btn",
    help_btn: "help_btn",
    email_fld: "email_fld",
    policy_number_fld: "policy_number_fld",
    login_captcha_btn: "login_captcha_btn",
    login_btn: "login_btn",
    policy_select_dd: "policy_select_dd",
    renew_btn: "renew_btn",
    change_current_policy_btn: "change_current_policy_btn",
    documents_btn: "documents_btn",
    make_change_btn: "make_change_btn",
    manage_drivers_btn: "manage_drivers_btn",
    show_more_details_btn: "show_more_details_btn",
    back_btn: (index) => `back${index || ""}_btn`,
    renew_when_expires_btn: "renew_when_expires_btn",
    rn_change_btn: "rn_change_btn",
    confirm_dob_rn_expires_fld: "confirm_dob_rn_expires_fld",
    next_rn_expires_btn: "next_rn_expires_btn",
    documents_rn_btn: "documents_rn_btn",
    validation_receipt_lnk: "validation_receipt_lnk",
    policy_documents_lnk: "policy_documents_lnk",
    ipid_lnk: "ipid_lnk",
    quote_continue_rn_btn: "quote_continue_rn_btn",
    card_select_rn_btn: "card_select_rn_btn",
    add_card_rn_btn: "add_card_rn_btn",
    pay_rn_btn: "pay_rn_btn",
    rn_contact_email_btn: "rn_contact_email_btn",
    view_policy_btn: "view_policy_btn",
    validation_receipt2_lnk: "validation_receipt2_lnk",
    policy_wording_lnk: "policy_wording_lnk",
    policy_documents2_lnk: "policy_documents2_lnk",
    documents_sodn_lnk: "documents_sodn_lnk",
    ipid2_lnk: "ipid2_lnk",
    tob2_lnk:"tob2_lnk",
    renewal_schedule_lnk: "renewal_schedule_lnk",
    change_cover_type_btn: "change_cover_type_btn",
    add_additional_cover_btn: "add_additional_cover_btn",
    change_start_date_btn: "change_start_date_btn",
    change_address_btn: "change_address_btn",
    change_details_btn: "change_details_btn",
    manage_drivers2_btn: "manage_drivers2_btn",
    adjust_ar_btn: "adjust_ar_btn",
    cancel_policy_btn: "cancel_policy_btn",
    european_annual_mta_btn: "european_annual_mta_btn",
    european_daily_mta_btn: "european_daily_mta_btn",
    worldwide_annual_mta_btn: "worldwide_annual_mta_btn",
    worldwide_daily_mta_btn: "worldwide_daily_mta_btn",
    sli_mta_btn: "sli_mta_btn",
    family_mta_btn: "family_mta_btn",
    documents_MTA_dd: "documents_MTA_dd",
    policy_documents3_lnk: "policy_documents3_lnk",
    ipid3_lnk: "ipid3_lnk",
    continue_mta_btn: "continue_mta_btn",
    add_card_mta_btn: "add_card_mta_btn",
    pay_mta_btn: "pay_mta_btn",
    mta_contact_email_btn: "mta_contact_email_btn",
    view_mta_btn: "view_mta_btn",
    view_documents_mta_btn: "view_documents_mta_btn",
    enter_new_postcode_fld: "enter_new_postcode_fld",
    find_new_address_btn: "find_new_address_btn",
    enter_new_address_manually_btn: "enter_new_address_manually_btn",
    save_new_address: "save_new_address",
    change_first_name_fld: "change_first_name_fld",
    change_last_name_fld: "change_last_name_fld",
    change_dob_fld: "change_dob_fld",
    change_telephone_fld: "change_telephone_fld",
    // create function from multiple drivers for edit
    editAdditionalDriverBtn: (index) => `edit_additional_driver_${index}_btn`,
    // create function from multiple drivers for remove
    removeAdditionalDriverbtn: (index) =>
        `remove_additional_driver_${index}_btn`,
    // create function from multiple drivers for firstName
    additionalDriverFirstName: (index) =>
        `additional_driver_${index}_first_name_fld`,
    // create function from multiple drivers for surname
    additionalDriverSurname: (index) =>
        `additional_driver_${index}_surname_fld`,
    // create function from multiple drivers for dob
    additionalDriverDob: (index) => `additional_driver_${index}_dob_fld`,
    add_additional_driver_btn: "add_additional_driver_btn",
    save_additional_drivers_btn: "save_additional_drivers_btn",
    ar_switch_btn: "ar_switch_btn",
    cancel_my_policy_btn: "cancel_my_policy_btn",
    cancel_first_name_fld: "cancel_first_name_fld",
    cancel_surname_fld: "cancel_surname_fld",
    cancel_email_address_fld: "cancel_email_address_fld",
    cancel_telephone_fld: "cancel_telephone_fld",
    cancel_policy_number_fld: "cancel_policy_number_fld",
    confirm_cancellation_btn: "confirm_cancellation_btn",
    cancel_confirmation_btn: "cancel_confirmation_btn",
    change_your_details: 'change_your_details',
    text_message: 'text_message',
    declaration_renewal: 'declaration_renewal',
    how_we_do_business_renewal: 'how_we_do_business_renewal',
    continue_payment_renewal: "continue_payment_renewal",
    your_policy: "your_policy",
    billing_details: "billing_details",
    documents:"documents",
    signout:'signout'
};




export const dataClickGTM = {
    get_quote_btn: "Get a quote",
    claims_btn: "Claims",
    faq_btn: "FAQ",
    contact_us_btn: "Contact Us",
    help_btn: "Help",
    email_fld: "Email Address",
    policy_number_fld: "Policy Number",
    login_captcha_btn: "Login Captcha",
    login_btn: "Login",
    policy_select_dd: "Policy Select",
    renew_btn: "Renew",
    change_current_policy_btn: "Change current policy",
    documents_btn: "Documents",
    make_change_btn: "Make a change",
    manage_drivers_btn: "Manage drivers",
    show_more_details_btn: "Show more details",
    back_btn: (index) => `${index ? `Back ${index}` : "Back"}`,
    renew_when_expires_btn: "Renew when expires",
    rn_change_btn: "Make renewal change",
    confirm_dob_rn_expires_fld: "Confirm DOB expires",
    next_rn_expires_btn: "Next renewal expires",
    documents_rn_btn: "Documents",
    validation_receipt_lnk: "Validation and receipt",
    policy_documents_lnk: "Policy documents",
    ipid_lnk: "IPID",
    quote_continue_rn_btn: "Renewal continue",
    card_select_rn_btn: "Card select",
    add_card_rn_btn: "Add new card",
    pay_rn_btn: "Renewal pay",
    rn_contact_email_btn: "Renewal contact email",
    view_policy_btn: "View policy",
    validation_receipt2_lnk: "Validation and receipt 2",
    policy_wording_lnk: "Policy wording",
    policy_documents2_lnk: "Policy documents 2",
    documents_sodn_lnk: "Statement of demands and needs",
    ipid2_lnk: "IPID 2",
    tob2_lnk:"Terms of Business",
    renewal_schedule_lnk: "Renewal schedule",
    change_cover_type_btn: "Change cover type",
    add_additional_cover_btn: "Add additional cover",
    change_start_date_btn: "Change start date",
    change_address_btn: "Change address",
    change_details_btn: "Change details",
    manage_drivers2_btn: "Manage drivers 2",
    adjust_ar_btn: "Adjust auto renewals",
    cancel_policy_btn: "Cancel policy",
    european_annual_mta_btn: "European Annual Cover MTA",
    european_daily_mta_btn: "European Daily Cover MTA",
    worldwide_annual_mta_btn: "Worldwide Annual Cover MTA",
    worldwide_daily_mta_btn: "Worldwide Daily Cover MTA",
    sli_mta_btn: "Supplementary Liability Insurance MTA",
    family_mta_btn: "Family Cover MTA",
    documents_MTA_dd: "Documents MTA",
    policy_documents3_lnk: "Policy documents",
    ipid3_lnk: "IPID",
    continue_mta_btn: "Continue to payment MTA",
    add_card_mta_btn: "Add new card MTA",
    pay_mta_btn: "Pay MTA",
    mta_contact_email_btn: "Contact email MTA",
    view_mta_btn: "View MTA",
    view_documents_mta_btn: "View documents MTA",
    enter_new_postcode_fld: "Enter new postcode",
    find_new_address_btn: "Find new address",
    enter_new_address_manually_btn: "Enter new address manually",
    save_new_address: "Save new address",
    change_first_name_fld: "Change first name",
    change_last_name_fld: "Change last name",
    change_dob_fld: "Change date of birth",
    change_telephone_fld: "Change telephone",
    // create function from multiple drivers for edit
    editAdditionalDriverBtn: (index) => `Edit additional driver ${index}`,
    // create function from multiple drivers for remove
    removeAdditionalDriverbtn: (index) => `Remove additional driver ${index}`,
    // create function from multiple drivers for firstName
    additionalDriverFirstName: (index) =>
        `Additional driver ${index} first name`,
    // create function from multiple drivers for surname
    additionalDriverSurname: (index) => `Additional driver ${index} surname`,
    // create function from multiple drivers for dob
    additionalDriverDob: (index) => `Additional driver ${index} date of birth`,
    add_additional_driver_btn: "Add additional driver",
    save_additional_drivers_btn: "Save additional drivers",
    ar_switch_btn: "Automatic renewal switch",
    cancel_my_policy_btn: "Cancel my policy",
    cancel_first_name_fld: "Cancel first name",
    cancel_surname_fld: "Cancel surname",
    cancel_email_address_fld: "Cancel email address",
    cancel_telephone_fld: "Cancel telephone",
    cancel_policy_number_fld: "Cancel policy number",
    confirm_cancellation_btn: "Confirm cancellation",
    cancel_confirmation_btn: "Cancel cancellation",
    change_your_details: "Change your details",
    text_message: "Text message",
    declaration_renewal: "Declaration renewal",
    how_we_do_business_renewal: "How we do business renewal",
    continue_payment_renewal: "Continue to payment renewal",
    your_policy: "Your Policy",
    billing_details: "Billing Details",
    documents:"Documents",
    signout:'Signout'

};

const actionsGtm = {
    button: "Button Click",
    feild: "Field Click",
    dropdown: "Drop Down Click",
    link: "Link Click",
};

export const TrackButtonEvent = (target) =>
    TrackInteractionEvent(actionsGtm.button, target.dataset);

export const TrackDropdownEvent = (target) =>
    TrackInteractionEvent(actionsGtm.dropdown, target.dataset);

export const TrackFieldEvent = (target) =>
    TrackInteractionEvent(actionsGtm.feild, target.dataset);

export const TrackLinkEvent = (target) =>
    TrackInteractionEvent(actionsGtm.link, target.dataset);
