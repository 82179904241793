import * as Organisations from "./companies";
let currentOrganisation = null;

const urls = {
    "sohail.selfservice.dev.halolynx.com": Organisations.CAR_HIRE_EXCESS_UK,
    "sohail.selfservice.uk.dev.halolynx.com": Organisations.CAR_HIRE_EXCESS_UK,
    "tahir.selfservice.dev.halolynx.com": Organisations.CAR_HIRE_EXCESS_UK,
    "tahir.selfservice.uk.dev.halolynx.com": Organisations.CAR_HIRE_EXCESS_UK,
    "selfservice.uk.dev.halolynx.com": Organisations.CAR_HIRE_EXCESS_UK,
    "selfservice.uk.ppr.halolynx.com": Organisations.CAR_HIRE_EXCESS_UK,
    "qa.selfservice.ppr.halolynx.com": Organisations.CAR_HIRE_EXCESS_UK,
    "talha.selfservice.dev.halolynx.com": Organisations.CAR_HIRE_EXCESS_UK,
    "mypolicy.carhireexcess.co.uk": Organisations.CAR_HIRE_EXCESS_UK,
    "mypolicy2.carhireexcess.co.uk": Organisations.CAR_HIRE_EXCESS_UK,

    "sohail.selfservice.ie.dev.halolynx.com": Organisations.CAR_HIRE_EXCESS_IE,
    "talha.selfservice.ie.dev.halolynx.com": Organisations.CAR_HIRE_EXCESS_IE,
    "tahir.selfservice.ie.dev.halolynx.com": Organisations.CAR_HIRE_EXCESS_IE,
    "selfservice.ie.dev.halolynx.com": Organisations.CAR_HIRE_EXCESS_IE,
    "selfservice.ie.ppr.halolynx.com": Organisations.CAR_HIRE_EXCESS_IE,
    "qa.selfservice.ie.ppr.halolynx.com": Organisations.CAR_HIRE_EXCESS_IE,
    "mypolicy.carhireexcess.ie": Organisations.CAR_HIRE_EXCESS_IE,
    "mypolicy2.carhireexcess.ie": Organisations.CAR_HIRE_EXCESS_IE,
};

const DEFAULT_ORGANISATION = Organisations.CAR_HIRE_EXCESS_UK;

const getCurrentOrganisation = () => {
    if (!currentOrganisation) {
        if (urls[window.location.hostname]) {
            currentOrganisation = urls[window.location.hostname];
        } else {
            currentOrganisation = DEFAULT_ORGANISATION;
        }
    }

    return currentOrganisation;
};

export { getCurrentOrganisation };
